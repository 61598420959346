import { useEffect, useState } from 'react';
import CryptoJS from "crypto-js";
import Debug from 'debug';

const debug = Debug('Payment:useEncryptedLocalStorage');

class Crypt<T> {
  password:string;
  constructor(password:string) {
    this.password = password;
  }
  encrypt <T>(decrypted:T) {
    const state = JSON.stringify(decrypted);
    const cipher = CryptoJS.AES.encrypt(state, this.password).toString();
    return cipher;
  }
  decrypt (enc:string):T {
    const bytes = CryptoJS.AES.decrypt(enc, this.password);
    const body = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(body);
  }
}

type ReturnValue<T> = readonly [
  T,
  (value: T | ((val: T) => T)) => void,
  (value:string) => void,
  () => void
] 

export default function useEncryptedLocalStorage<T>(storedPassword:string, key: string, initialValue: T):ReturnValue<T> { 
  const [password, setPassword] = useState(storedPassword);
  const [storedValue, setDecrypted] = useState<T>(initialValue);
  
  useEffect(() => {
    debug('getValue', password);
    if (password == '') return; 
    const crypt = new Crypt<T>(password);
    try {
      const item = window.localStorage.getItem(`enc-${key}`);
      if (item) setDecrypted(crypt.decrypt(item));
    } catch(err) {
      debug(err);
      window.localStorage.removeItem(`enc-${key}`)
    }
  }, [password]);

  const removeValue = () => {
    try {
      window.localStorage.removeItem(`enc-${key}`);
    } catch (error) {
      debug(error);
    }
  }

  const setValue = (value: T | ((val: T) => T)) => {
    debug('setValue', value, storedValue, password);
    if (password == '' && value == storedValue) return; 
    const crypt = new Crypt<T>(password);
    try {
      const valueToStore = value instanceof Function ? value(storedValue as T) : value;
      setDecrypted(valueToStore)
      window.localStorage.setItem(`enc-${key}`, crypt.encrypt(valueToStore));
    } catch (error) {
      debug(error);
    }
  };


  return [storedValue, setValue, setPassword, removeValue] as const;
}
