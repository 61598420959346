
import React, { useEffect, useContext, useState, MouseEvent } from 'react';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import AppContext from '../app-context';
import Debug from 'debug';
const debug = Debug('Payment:Components:PMTFrame');

const useStyles = makeStyles((theme) => ({
  frame: {
    width: '100%', // Fix IE 11 issue.
    height: '700px',
    marginTop: theme.spacing(2),
    border: "none"
  }
}));

import * as z from 'zod';

export const PMTResponseSchema = z.object({
  CCNO: z.string(),
  REFCARDNO: z.string(),
  CARDTYPE: z.string(),
  EXPIRYDATE: z.string(),
  ERRORCODE: z.string(),
  ERRORTEXT: z.string(),
  CUSTOMERNO: z.unknown(),
  ACTION: z.enum(["OK","NOK","CANCEL"])
});

export const PMTMinimalResponseSchema = z.object({
  ERRORCODE: z.string().optional(),
  ERRORTEXT: z.string().optional(),
  ACTION: z.enum(["OK","NOK","CANCEL"])
}).nonstrict();

export type PMTData = {
  Number: string,
  NumberMasked: string,
  Code: string,
  ExpirationDate: string  
}

type PMTFrameProps = { onData: (data:PMTData) => void, onError?: (error:PMTError) => void }

const sid = Math.random().toString(36).substr(2, 9);

export class PMTError extends Error {}

export default function PMTFrame(props:PMTFrameProps):JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [appState] = useContext(AppContext);
  const [data, setData] = useState<PMTData>();
  const [error, setError] = useState<PMTError>();
  const handleReset = (e:MouseEvent<HTMLButtonElement>) => {
    setData(undefined);
    setError(undefined);
    e.preventDefault();
  }
  useEffect(() => {
    const handler = (event:MessageEvent) => {
      if (event.origin != appState.PMTReturn) return;
      if (event.data?.source == "react-devtools-bridge" ) return;
      try {
        if (!event.data?.RESPONSE?.ACTION) throw new Error('no response');
        debug("got result", event.data);
        const resp = PMTMinimalResponseSchema.parse(event.data.RESPONSE);

        if (resp.ACTION != 'OK') {
          throw new Error(resp.ACTION == 'CANCEL' ? 'transaction canelled' : resp.ERRORTEXT || 'unknown error' );
        }
        const data = PMTResponseSchema.parse(event.data.RESPONSE);
        const result = {
          Number: data.REFCARDNO,
          NumberMasked: data.CCNO,
          Code: data.CARDTYPE == 'AM' ? 'AX' : data.CARDTYPE,
          ExpirationDate: data.EXPIRYDATE
        };
        setData(result);
        props.onData(result);
      } catch (err) {
        props.onError && props.onError(err);
        setError(err)
      }
    }
    window.addEventListener("message", handler)
    // clean up
    return () => window.removeEventListener("message", handler)
  }, []) // empty array => run only once

  const returnTo = `${appState.PMTReturn}${appState.PMTReturnPath}`;
  const url = `${appState.PMTFrameURL}&sid=${sid}&responseUrl=${window.btoa(returnTo)}`
  return (!(error || data)) ? <iframe className={classes.frame} src={url}></iframe> : <> {(error ? <p>{error.message}</p> : <p>{data?.Code} {data?.NumberMasked} given</p>)} <Button 
    type="submit"
    variant="contained"
    onClick={handleReset}
  >{t("Pay.resetPMTErrorButton")}</Button></>
} 
