import React, { useState, useContext, useEffect } from 'react';
import Debug from 'debug';
const debug = Debug('Payment');
import { FeathersError } from "@feathersjs/errors";

import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import 'fontsource-roboto';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import * as Alert from '@material-ui/lab/Alert';

import AppContext, { State as  AppStateType} from './app-context';
import Form from './Components/Form';
import Login from './Components/Login';
import Logout from './Components/Logout';
import i18nInit from './i18n';
import { Grid } from '@material-ui/core';
import logo from "url:../images/ico-logo.png";

i18nInit();

function logSetState(setState:React.Dispatch<React.SetStateAction<AppStateType>>) {
  return (value:AppStateType) => {
    debug("setGlobal", value);
    setState(value);
  }
}

const useStyles = makeStyles((theme) => ({
  root: {

  },
  logo: {
    padding: "20px 0 0 0"
  },
  bar: {
    width: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

function Bar(props:{ children: JSX.Element }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return <div className={classes.bar}>
    <Typography variant="h3">{ t('PaymentPage') }</Typography>
    {props.children}
  </div>
}

type AlertType = { severity?: string, message?: string, visible: boolean };

const Message = (props:{alert:AlertType, onDismiss:() => void}) => {
  return <Snackbar
    anchorOrigin={ {vertical: 'top', horizontal: 'center'} }
    open={props.alert.visible}
    onClose={props.onDismiss}
    autoHideDuration={6000}
  ><Alert.default onClose={props.onDismiss} severity={props.alert.severity as Alert.Color}>{props.alert.message ? props.alert.message : 'Ein unbekannter Fehler ist aufgetreten'}</Alert.default></Snackbar>;
}

function App():JSX.Element {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { BookingNumber, Language } = useParams<{BookingNumber:string, Language:string}>();

  const [ctxt] = useContext(AppContext);
  const [alert, setAlert] = useState<AlertType>({ visible: false });
  const [state, setAppState] = useState<AppStateType>({...ctxt, Language, BookingNumber: parseInt(BookingNumber, 10) });
  const setState = logSetState(setAppState);

  useEffect(() => {
    state.App.reAuthenticate().then( (auth:any) => {
      setState({...state, LoggedIn: true});      
    }).catch(() => {
      setState({...state, LoggedIn: false});      
    });
  }, [])


  const handleLogout = () => {
    state.App.logout().then( (auth:any) => {
      setState({ ...state, Password: '', LoggedIn: false});
    })
  }

  const handleError = (error:Error) => {
    let message = error.message;
    if (error instanceof FeathersError) {
      if (error.code == 401) message = t('Login.NotAuthenticated');
      handleLogout();
    }
    setAlert({ severity: 'error', message, visible: true});
  }
  const handleDismissError = () => {
    setAlert({...alert, visible: false});
  };
  useEffect(() => {
    i18n.changeLanguage(Language);
  }, [])
  const value:[AppStateType,(value:AppStateType) => void] = [state, (props:AppStateType) => setState(props)];
  return (
    <AppContext.Provider value={value}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Grid container spacing={2} className={classes.root}>
          <Message alert={alert} onDismiss={handleDismissError} />
          <Grid item xs={12}>
            <img src={logo} className={classes.logo}/>
          </Grid>
          <Grid item xs={12}>
          {( state.LoggedIn ? 
            <><Form onError={handleError}><Bar><Logout handleLogout={handleLogout} /></Bar></Form></> : 
            <Login onError={handleError}/> )}
          </Grid>
        </Grid>
      </Container>
    </AppContext.Provider>
  );
}

export default App;
