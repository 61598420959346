import React, { useState, useContext, useEffect, ChangeEvent } from 'react';
import { parse } from 'date-fns'

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import AppContext, { State } from '../app-context';
import useInput from '../useInput';

import Debug from 'debug';
const debug = Debug('Payment:Components:Login');

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  row: {
    marginTop: theme.spacing(2),
  }
}));

const LoginForm = (props:{ onClick: (password:string) => void}) => {
  const { t } = useTranslation();
  const classes = useStyles();  

  const validateBirthday = (ev:ChangeEvent<HTMLInputElement>) => {
    const bday = parse(ev.target.value,"dd.MM.yyyy",new Date());
    if (!(ev.target.value.length == 10 && bday < new Date())) return new Error(t('Login.ValidateBdayError'))
  }

  const validateBookingNumber = (ev:ChangeEvent<HTMLInputElement>) => {
    if (!(ev.target.value.length > 4 && ev.target.value.match(/^[A-Z0-9]+$/))) return new Error(t('Login.ValidateBooknumberError'))
  }
  const [birthday, setBirthday, birthdayValidationError] = useInput('', validateBirthday);
  const [bookingNumber, setBookingNumber, bookingNumberValidationError] = useInput('', validateBookingNumber);

  const isReady = birthday && !(birthdayValidationError instanceof Error) && bookingNumber && !(bookingNumberValidationError instanceof Error);

  return <form className={classes.form} noValidate>
  <TextField 
    className={classes.row}
    placeholder="DD.MM.YYYY" 
    onChange={setBirthday} 
    label={t("Login.Birthday")}
    value={birthday}
    error={birthdayValidationError instanceof Error}
    helperText={birthdayValidationError instanceof Error ? birthdayValidationError.message : ''}
    required
    fullWidth
  />
  <TextField 
    className={classes.row}
    placeholder="XYZ123" 
    onChange={setBookingNumber} 
    label={t("Login.BookingNumber")}
    value={bookingNumber} 
    error={bookingNumberValidationError instanceof Error}
    helperText={bookingNumberValidationError instanceof Error ? bookingNumberValidationError.message : ''}
    required
    fullWidth
  />
  <Button 
    className={classes.row}
    onClick={(e) => {
      e.preventDefault();
      if (isReady) {
        const [d,m,y] = birthday.split(".");
        props.onClick(`${y}-${m}-${d}:${bookingNumber}`);
      }
    }}
    disabled={!isReady}
    type="submit"
    variant="contained"
    fullWidth
    color="primary"
  >{t('Login.verify')}
  </Button>
</form>
} 

const fetchTokenWithState = async (appState:State):Promise<void> => {

  await appState.App.authenticate({
    strategy: 'token',
    createToken: appState.Password
  }, { headers: { 
    "x-token-id": appState.BookingNumber,
    "x-token-service": `${appState.Mandant}/genvas`
  }}).then( (result:any ) => {
    console.log(result)
  })

  // const auth = appState.App.service('authentication');
  // return appState.Password ? auth.create({ strategy: 'token', createToken: appState.Password }, { headers: { 
  //   "x-token-id": appState.BookingNumber,
  //   "x-token-service": "genvas"
  // }}).then((result:{accessToken:string}) => {
  //   if ( typeof(result) == 'object' && result.accessToken) {
  //     return result.accessToken;
  //   } 
  //   throw new Error('not authenticated');
  // }) : Promise.resolve('');

}

const useToken = function() {
  const [appState] = useContext(AppContext);
  const [error, setError] = useState<Error>();
  const [password, setPassword] = useState('');

  // const [token, setToken, setTokenPassword] = useEncryptedLocalStorage<string>(appState.Password, "token", "");

  const handleError = (err:Error) => {
    setError(err);
    setPassword('');
  }

  const handlePassword = (password:string) => {
    debug('useToken:handlePassword')
    setError(undefined);
    setPassword(password);
  }

  useEffect(() => { 
    if (!password) return; 
    debug('useToken:fetchToken')
    fetchTokenWithState({...appState, Password:password}).catch(handleError);
  }, [password, handleError] );

  return [{password, error}, handlePassword] as const;
}

export default function Login(props:{onError?:(err:Error)=> void}):JSX.Element {
  const [appState, setAppState] = useContext(AppContext);
  const [{password, error}, setPassword] = useToken();

  useEffect(() => {
    if (password && !appState.LoggedIn) {
      setAppState({...appState, Password: password, LoggedIn: true });
    }
  }, [password, setAppState]);

  useEffect(() => {
    if (error && props.onError) {
      props.onError(error);
    }
  }, [error]);

  async function handleLogin(Password:string) {
    setPassword(Password);
  }

  return <LoginForm onClick={handleLogin} />
}
