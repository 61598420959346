import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

export default function():void {

  i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          "BookingNumberLabel": "Booking number:",
          "PaymentPage": "Payment page",
          "ErrorLabel": "Error",
          "BookingDisplayError": "Booking can not be displayed at this time",
          "Login": {
            "Birthday": "Birthday of first passenger",
            "BookingNumber": "Booking number from cruiseline",
            "verify": 'Verify access',
            "NotAuthenticated": "Could not authenticate you",
            "ValidateBdayError": "not a valid birthday",
            "ValidateBooknumberError": "not a valid Bookingnumber"
          },
          "Service": {
            "Cruise": "Cruise",
            "Tax": "Taxes",
            "Flight": "Flight"
          },
          "Pay": {
            "MaxAmountExceeded": "amount is too high",
            "AdjustSliderLabel": "Please adjust payment",
            "Amount": "Amount",
            "SplitChoiceLabel": "split payments on multiple credit cards",
            "SplitPayButton": "Pay remaining {{formattedAmount}} now",
            "PayButton": "Pay remaining {{formattedAmount}} now",
            "OtherCreditcardButton": "change to other credit card",
            "StillToPayLabel": "Still to pay:",
            "AlreadyPaidLabel": "Already paid:",
            "TotalLabel": "Total:",
            "resetPMTErrorButton": "Reset"
          }
        }
      },
      de: {
        translation: {
          "BookingNumberLabel": "Vorgangsnummer:",
          "PaymentPage": "Zahlungsübersicht",
          "ErrorLabel": "Fehler",
          "BookingDisplayError": "Die Buchung kann zur Zeit nicht dargestellt werden",
          "Login": {
            "Birthday": "Geburtstag des ersten Passagiers",
            "BookingNumber": "Buchungsnummer der Reederei",
            "verify": 'Zugang prüfen',
            "NotAuthenticated": "Ihre Angaben sind nicht korrekt",
            "ValidateBdayError": "Das eingegebene Geburtsdatum ist nicht korrekt",
            "ValidateBooknumberError": "Die eingegebene Buchungnummer ist nicht korrekt"
          },
          "Service": {
            "Cruise": "Kreuzfahrt",
            "Tax": "Hafengebühren/Steuern",
            "Flight": "Flug"
          },
          "Pay": {
            "MaxAmountExceeded": "Betrag ist zu hoch",
            "AdjustSliderLabel": "Bitte geben Sie einen Teilbetrag für die gewählte Kreditkarte an",
            "Amount": "Teilbetrag",
            "SplitChoiceLabel": "Teilen Sie Zahlungen auf mehrere Karten",
            "SplitPayButton": "Betrag {{formattedAmount}} sofort anweisen",
            "PayButton": "Betrag {{formattedAmount}} sofort anweisen",
            "OtherCreditcardButton": "Andere Kreditkarte erfassen",
            "StillToPayLabel": "Noch zu zahlen:",
            "AlreadyPaidLabel": "Bereits gezahlt:",
            "TotalLabel": "Rechnungssume:",
            "resetPMTErrorButton": "zurück zur Karteneingabe"
          }
        }
      }
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });
}