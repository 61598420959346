import React, { useEffect, useState, useContext , MouseEvent } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Debug from 'debug';
const debug = Debug('Payment:Components:TokenRedirect');
import AppContext from '../app-context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    button: {
      whiteSpace: 'nowrap',
      marginLeft: theme.spacing(1), 
    }
  }),
);

const getNodeText = (doc:Element, name:string):string|null => {
  const elem = doc.getElementsByTagName(name);
  if (elem && elem[0] && elem[0].childNodes[0]) return elem[0].childNodes[0].nodeValue;
  return "";
}

export default function TokenRedirect():JSX.Element {
  const classes = useStyles();
  const [appState] = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [response, setResponse] = useState<string>('');

  const clickHandler = (ev:MouseEvent<HTMLButtonElement>) => {
    window.location.href = "";
    ev.stopPropagation();
  }
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setResponse(params.get("xmlresponse") || '');
  }, [window.location.search]);

  useEffect(() => {
    if (!response) return;
    setProcessing(true);
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(response, "text/xml");
    const [pmt_token] = xmlDoc.getElementsByTagName('PMT_TOKEN');
    const [pmt_response] = pmt_token.getElementsByTagName('RESPONSE');
    const ACTION = getNodeText(pmt_response, 'ACTION');
    const result = (ACTION != 'OK') ? {
      ERRORCODE: getNodeText(pmt_response, 'ERRORCODE'),
      ERRORTEXT: getNodeText(pmt_response, 'ERRORTEXT'),
      CUSTOMERNO: getNodeText(pmt_response, 'CUSTOMERNO'),
      ACTION: getNodeText(pmt_response, 'ACTION')
    } : {
      CCNO: getNodeText(pmt_response, 'CCNO'),
      REFCARDNO: getNodeText(pmt_response, 'REFCARDNO'),
      CARDTYPE: getNodeText(pmt_response, 'CARDTYPE'),
      EXPIRYDATE: getNodeText(pmt_response, 'EXPIRYDATE'),
      ERRORCODE: getNodeText(pmt_response, 'ERRORCODE'),
      ERRORTEXT: getNodeText(pmt_response, 'ERRORTEXT'),
      CUSTOMERNO: getNodeText(pmt_response, 'CUSTOMERNO'),
      ACTION: getNodeText(pmt_response, 'ACTION')
    }
    debug("got result", result);
    window.parent.postMessage({RESPONSE: result}, '*');
    setProcessing(false);
  }, [response])  

  return (
    <>
    { response != '' ? <Button 
        className={classes.button}
        type="submit"
        variant="contained"
        color="secondary"
        onClick={clickHandler}
    >reset</Button> : "" } 
    <Backdrop className={classes.backdrop} open={processing}>
      <CircularProgress color="inherit" />
    </Backdrop>
  </>
  )
}