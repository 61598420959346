import React from 'react';
import Button from '@material-ui/core/Button';

export default function Logout(props:{handleLogout:() => void}):JSX.Element {
  return <Button 
    onClick={() => props.handleLogout()}
    type="submit"
    variant="contained"
    color="primary"
  >logout</Button>
}