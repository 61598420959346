import React, {useContext} from 'react';
import { parseISO, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import AppContext from '../app-context';
import ErrorBoundary from './ErrorBoundary';

const useStyles = makeStyles((theme) => ({
  row: {
    listStyleType: "none",
    marginTop: theme.spacing(1),
    paddingInlineStart: 0,
    display: 'flex',
    width: "100%",
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'left',
  },
  label: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  range: {
  },
  price: {
    textAlign: "right",
    borderBottom: '1px solid #333',
  }

}));

export type Service = {
  BookingDate: string,
  BookingCodeName: string,
  Accommodation: string,
  Catalogue: string,
  From: string,
  Till: string,
  SalesPrice?: {
    Currency: string,
    Commission_VAT_Plus: boolean,
    Text: string,
    [k:string]: number | boolean | string
  },
  Service: string,
  State: "OK" |"RQ" |"OP" |"XX",
  Requirement: string
}

export default function ServiceList(props: { services:Service[] }):JSX.Element {
  const classes = useStyles();
  return <ErrorBoundary><ul className={classes.row}>
  {props.services.map((s, i) => <li key={i}><Service service={s} /></li>)}
  </ul></ErrorBoundary>
}

export function Service({ service}:{service:Service}):JSX.Element {
  const classes = useStyles();
  const [appState] = useContext(AppContext);
  const { t } = useTranslation();

  const formatter = new Intl.NumberFormat(appState.Language, {
    style: 'currency',
    currency: service.SalesPrice?.Currency || 'EUR',
    minimumFractionDigits: 2
  })

  const formatCurrency = (v:string|number|boolean) => formatter.format(parseFloat(v.toString()))

  const from = format(parseISO(service.From), 'dd.MM.yyyy');
  const till = format(parseISO(service.Till), 'dd.MM.yyyy');
  const date_range = (from != till) ? `${from} - ${till}` : from; 
  const price = service.SalesPrice ? Object.entries(service.SalesPrice).reduce((out, [k, v]) => {
    if (!v) return out;
    if (k == 'Schiff brutto') out.push(`${t('Service.Cruise')} ${formatCurrency(v)}`);
    if (k == 'Hafentaxe netto') out.push(`${t('Service.Tax')} ${formatCurrency(v)}`);
    if (k == 'Flug brutto') out.push(`${t('Service.Flight')} ${formatCurrency(v)}`);
    return out;
  }, [] as string[]) : [];
  return <>
    <div className={classes.label}>
      <Typography variant="h6">{service.BookingCodeName} {service.Accommodation}</Typography> 
      <Typography variant="body1">{date_range}</Typography> 
    </div>
    <Typography className={classes.price} variant="body1">{price.join(' ')}</Typography>
  </>
}