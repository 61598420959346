import React, { ChangeEvent, MouseEvent, useContext } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTranslation } from 'react-i18next';

import { PMTData } from './PMTFrame';
import { Booking } from './Booking';
import AppContext from '../app-context';

const useStyles = makeStyles((theme) => ({
  payment: {
    marginTop: theme.spacing(3), 
    width: "100%",
    display: "flex",
    flexDirection: 'column'
  },
  paymentLine: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  button: {
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(1), 
    alignSelf: 'flex-end'
  }
}));

const PaymentSlider = withStyles({
  root: {
    color: '#ff0000',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);


type PaymentData = PMTData & { Amount: number };

type PayButtonProps = {
  payment: PMTData,
  booking: Booking,
  maxAmount: number,
  onPay: (data:PaymentData) => void
}

const AmountInput = (props:{error:string, value:number, currency:string, onChange:(value:number) => void }) => {
  const [appState] = useContext(AppContext);
  const {value, error:errorMessage, currency, onChange: onChangeValue } = props;
  const onChange = (ev:ChangeEvent<HTMLInputElement>) => onChangeValue(+ev.target.value || 0)
  const error = (errorMessage != '') ? { error: true } : {};
  const formatter = new Intl.NumberFormat(appState.Language, { minimumFractionDigits: 0 });
  return <TextField 
    value={formatter.format(value)} 
    helperText={errorMessage}
    InputProps={{
      onChange, 
      ...error,
      endAdornment: <InputAdornment position="end">{currency}</InputAdornment>
  }} />
}

export default function PayButton(props:PayButtonProps):JSX.Element {
  const { booking, payment, maxAmount } = props;
  const [amount, setAmount] = React.useState<number>(maxAmount);
  const [error, setError] = React.useState<string>();
  const classes = useStyles();
  const [appState] = useContext(AppContext);
  const { t } = useTranslation();

  const formatter = new Intl.NumberFormat(appState.Language, {
    style: 'currency',
    currency: booking.SalesCurrency,
    minimumFractionDigits: 0
  })
  const formatCurrency = (v:string|number|boolean) => formatter.format(parseFloat(v.toString()));

  const handleClick = (ev: MouseEvent<HTMLButtonElement>) => {
    props.onPay({Amount: amount, ...payment});
    ev.preventDefault();
  }

  const handleInput = (value:number) => {
    if (value > maxAmount) setError(t('Pay.MaxAmountExceeded'));
    else setError('');
    setAmount(value);
  }

  const handleChange = (event: unknown, newValue: number | number[]) => {
    handleInput(+newValue);
  };

  return <div className={classes.payment}>
    <Typography variant="h5" gutterBottom>{t("Pay.AdjustSliderLabel")}</Typography>
    <PaymentSlider 
      onChange={handleChange}
      valueLabelDisplay="off" 
      min={Math.floor(maxAmount / 10)}
      step={1}
      max={maxAmount}
      value={amount} 
    />
    <div className={classes.paymentLine}>
      <Typography variant="h5" gutterBottom>{t('Pay.Amount')}: <AmountInput error={error || ''} onChange={handleInput} value={amount} currency={booking.SalesCurrency}/></Typography>
      <Button 
        className={classes.button}
        type="submit"
        variant="contained"
        color="secondary"
        onClick={handleClick}
      >{t('Pay.SplitPayButton',{ formattedAmount: formatCurrency(amount)})}</Button>
    </div>
  </div>
}
