import React, { useState, useContext, useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import ErrorBoundary from './ErrorBoundary';
import AppContext from '../app-context';
import Booking, { Booking as BookingT} from './Booking';
import useEncryptedLocalStorage from '../useEncryptedLocalStorage';
import { PMTData } from './PMTFrame';
import PaymentOption, { PaymentData } from './PaymentOptions';

import Debug from 'debug';
const debug = Debug('Payment:Form');

type PaymentResponse = {
  Amount: number,
  Currency: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    }
  }),
);


export default function Form(props:{ onError?: (error:Error) => void, children: JSX.Element }):JSX.Element {
  const classes = useStyles();
  const [appState] = useContext(AppContext);
  const [booking, setBooking] = useState<BookingT>();
  const [amountPaidByClient, setAmountPaidByClient] = useState<number>(0);
  const [paymentResponse, setPaymentResponse] = useState<PaymentResponse>();
  const [processPayment, setProcessPayment] = useState(false);
  const [payment, setPayment] = useEncryptedLocalStorage<PMTData>(appState.Password, "pmt-token", {
    Code: '',
    ExpirationDate: '',
    Number: '',
    NumberMasked: ''
  });

  useEffect(() => {
    const genvas = appState.App.service(`${appState.Mandant}/genvas`);
    genvas.get(appState.BookingNumber).then(setBooking).catch((err:Error) => props.onError && props.onError(err))
  }, [paymentResponse]);

  const handlePayment = (data:PaymentData ) => {
    debug(data, booking);
    setProcessPayment(true);
    const genvas = appState.App.service(`${appState.Mandant}/genvas`);
    const { Amount, ...PaymentTypeDetails } = data;
    genvas.patch(appState.BookingNumber,{
      AddPayment: {
        PaymentType: 'Kreditkarte', 
        Amount, 
        Currency: booking?.SalesCurrency, 
        PaymentTypeDetails
      }
    }).then((result:PaymentResponse) => {
      setAmountPaidByClient(result.Amount);
      setPaymentResponse(result);
      setProcessPayment(false);
    }).catch(() => {
      setProcessPayment(false);
    });
  }
  debug(booking);

  return <> 
  {props.children}
  { booking ? <ErrorBoundary>
    <Booking booking={booking} payment={payment} amountPaid={amountPaidByClient} onPayment={setPayment} onPaymentError={props.onError} />
    { payment.Number != '' ? <PaymentOption booking={booking} payment={payment} onPay={handlePayment} /> : null}
    </ErrorBoundary> : null
  } 
  <Backdrop className={classes.backdrop} open={booking === undefined || processPayment}>
    <CircularProgress color="inherit" />
  </Backdrop>
  </>
}
