import React, { MouseEvent, useState, useContext, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import AppContext from '../app-context';
import { Booking as BookingT} from './Booking';
import { PMTData } from './PMTFrame';
import PayButton from './PayButton';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export type PaymentData = PMTData & { Amount: number };

export type PaymentOptionProps = {
  payment: PMTData,
  booking: BookingT,
  onPay: (data:PaymentData) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    options: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      marginTop: theme.spacing(3)
    },
    button: {
      whiteSpace: 'nowrap',
      alignSelf: 'flex-end',
    }  
  }),
);


export default function PaymentOption(props:PaymentOptionProps):JSX.Element {
  const [appState] = useContext(AppContext);
  const { t } = useTranslation();

  const [partialPaymentVisible, setPartialPaymentVisible] = useState(false);

  const { booking, payment, onPay } = props;
  const classes = useStyles();
  const formatter = new Intl.NumberFormat(appState.Language, {
    style: 'currency',
    currency: booking.SalesCurrency,
    minimumFractionDigits: 0
  })
  const formatCurrency = (v:string|number|boolean) => formatter.format(parseFloat(v.toString()));

  const maxAmount = booking.payment.AmountPaidByClient ? booking.payment.TotalSalesPrice - booking.payment.AmountPaidByClient : booking.payment.TotalSalesPrice;

  const handleDetailsChange = (event: ChangeEvent<HTMLInputElement>, checked:boolean) =>{
    setPartialPaymentVisible(checked)
  }

  const handleClick = (ev: MouseEvent<HTMLButtonElement>) => {
    onPay({Amount: maxAmount, ...payment});
    ev.preventDefault();
  }

  return (<>
    <FormGroup row className={classes.options}>
      <FormControlLabel label={t("Pay.SplitChoiceLabel")} control={
        <Switch checked={partialPaymentVisible} onChange={handleDetailsChange} />
      } />
       {!partialPaymentVisible ? <Button 
      className={classes.button}
      type="submit"
      variant="contained"
      color="secondary"
      onClick={handleClick}
    >{t('Pay.PayButton', { formattedAmount: formatCurrency(maxAmount) })}</Button> : null }
    </FormGroup>
    {partialPaymentVisible ? <PayButton booking={booking} payment={payment} maxAmount={maxAmount} onPay={onPay} /> : null}
    </>
  )
}