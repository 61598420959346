import React, { useEffect, useState } from 'react';

const easeOutQuad = (t:number) => t * ( 2 - t );
const frameDuration = 1000 / 60;

type Props = {from:number, to:number, duration?:number, formatter:(value:number) => string}

export default function CountUpAnimation( { from, to, duration = 2000, formatter }:Props ):JSX.Element {
  const [ count, setCount ] = useState( from );
  useEffect( () => {
    let frame = 0;
    const totalFrames = Math.round( duration / frameDuration );
    const counter = setInterval( () => {
      frame++;
      const progress = easeOutQuad( frame / totalFrames );
      setCount( from + (to - from) * progress);
      if ( frame === totalFrames ) {
        clearInterval( counter );
      }
    }, frameDuration );
    return () => clearInterval( counter );
  }, [to] );

  return <>{formatter(Math.floor( count ))}</>;
}