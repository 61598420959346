import React, { Component, ErrorInfo } from "react";
import { Translation } from 'react-i18next';
import * as Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      width: '100%',
      marginTop: theme.spacing(2)
    }
  }),
);

const StyledAlert = (props:{severity:string, children:React.ReactNode}) => {
  const classes = useStyles();
  return <Alert.default className={classes.alert} severity={props.severity as Alert.Color}>{props.children}</Alert.default>
};

type ErrorBoundaryT = { hasError: boolean}
class ErrorBoundary extends Component {
  public state = {
    hasError: false
  };
  public static getDerivedStateFromError():ErrorBoundaryT {
    return { hasError: true };
  }
  // set the types for error  and info
  public componentDidCatch(error: Error, info: ErrorInfo):void {
    console.error("ErrorBoundary caught an error", error, info);
  }
  public componentDidUpdate():void {
    if (this.state.hasError) {
      setTimeout(() => this.setState({ redirect: true }), 5000);
    }
  }
  public render():React.ReactNode {
    if (this.state.hasError) {
      return (
        <Translation>
          {
            (t) => <StyledAlert severity="error"><AlertTitle>{t("ErrorLabel")}</AlertTitle>{t('BookingDisplayError')}</StyledAlert>
          }
        </Translation>

      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
