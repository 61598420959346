import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    booking: {
      borderBottom: "5px solid black",
    }
  }),
);

export default function Home():JSX.Element {
  const classes = useStyles();
  
  return (
    <div className={classes.booking}>
      <Typography variant="h5" component="h3">Inter-Connect Payment</Typography>
    </div>
  )
}