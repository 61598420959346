import { useState, ChangeEvent } from 'react';

export default function useInput(initialValue:string, validator?:(e: ChangeEvent<HTMLInputElement>) => Error | void):[string, (e: ChangeEvent<HTMLInputElement>) => void, Error | null]{
  const [error, setError] = useState<Error|null>(null);
  const [value, setValue] = useState(initialValue);
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (validator) {
      const validationError = validator(e);
      if (validationError instanceof Error) setError(validationError)
      if (error && !validationError) setError(null);
    }
    setValue(e.target?.value);
  }
  return [value, handleChange, error];
}