import { createContext } from "react";
import feathers from "@feathersjs/feathers";
import rest from "@feathersjs/rest-client";
import auth from "@feathersjs/authentication-client";

import axios from 'axios';

function _win(name:string):string {
  return name in (window as any) ? (window as any)[name] : undefined; 
}

const CONFIG = {
  url: _win('APP_API_URL') || process.env.APP_API_URL || "",
  path: _win('APP_API_PATH') || process.env.APP_API_PATH || "",
  version: _win('APP_API_VERSION') || process.env.APP_API_VERSION || "",
  mandant: _win('APP_API_MANDANT') || process.env.APP_API_MANDANT || "",
  pmt_return: _win('APP_PMT_RETURN') || process.env.APP_PMT_RETURN || "",
  pmt_path: _win('APP_PMT_PATH') || process.env.APP_PMT_PATH || "",
  pmt_frame: _win('APP_PMT_FRAME') || process.env.APP_PMT_FRAME || ""
}  

console.log(CONFIG);

const app = feathers();
const restClient = rest(CONFIG.url);
app.configure(restClient.axios(axios, {
  headers: {
    common: {
      'x-api-version': CONFIG.version
    }
  }
}));

app.configure(auth({
  path: CONFIG.url.indexOf('localhost') > -1 ? '/authentication' :  '/../authentication'
}));

export type State = { 
  App: feathers.Application,
  BookingNumber: number, 
  Password: string,
  LoggedIn: boolean,
  Language: string,
  Mandant: string,
  PMTReturn: string,
  PMTReturnPath: string,
  PMTFrameURL: string
}

const defaultState = {
  App: app,
  BookingNumber: 0, 
  Password: '',
  LoggedIn: false,  
  Language: navigator.language,
  Mandant: CONFIG.mandant,
  PMTReturn: CONFIG.pmt_return,
  PMTReturnPath: CONFIG.pmt_path,
  PMTFrameURL: CONFIG.pmt_frame
}

export default createContext<[State, (props:State) => void]>([defaultState, () => { return }]);