import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

const Loading = () => <div>Loading...</div>;

// const App = React.lazy(() => import('./App'))
// const TokenRedirect = React.lazy(() => import('./Components/TokenRedirect'));
// const Home = React.lazy(() => import('./Components/Home'));

import App from './App'
import TokenRedirect from './Components/TokenRedirect';
import Home from './Components/Home';


function PaymentPage() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}:Language/:BookingNumber`}>
        <App />
      </Route>
      <Route path={match.path}>
        <Home />
      </Route>
  </Switch>
  );
}

function RoutedApp() {
  return (
    <Router>
      <Switch>
        <Route path="/token-redirect">
            <TokenRedirect />
        </Route>
        <Route path="/">
          <PaymentPage />
        </Route>
      </Switch>
   </Router>
  )
}

export default RoutedApp;