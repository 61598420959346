import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import AppContext from '../app-context';
import PMTFrame, { PMTData } from "./PMTFrame";
import CountUp from "./CountUp";

import ServiceList, { Service as ServiceT } from './Service';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  booking: {
    width: "100%"
  },
  balance: {
    textAlign: 'right'
  },
  resetbutton: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between",
    alignItems: "baseline"
  }
}));

export type Booking = {
  BookingNumber: string,
  SalesCurrency: string,
  payment: {
    PaymentType: "Kreditkarte" | "Ueberweisung" | "Lastschrift",
    AmountPaidByClient: number,
    TotalSalesPrice: number,
    PaymentDetails: PaymentDetails
  },
  services: ServiceT[]
}

type PaymentDetails = Record<string, string> | PMTData

type BookingProps = { booking: Booking, amountPaid:number, payment: PMTData, onPayment: (data:PMTData) => void, onPaymentError?: (err:Error) => void }

type ResetPaymentButtonProps = { payment:PMTData, onReset: (data:PMTData) => void }
function ResetPaymentButton(props:ResetPaymentButtonProps):JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return <div className={classes.resetbutton}>
    <Typography variant="h6">{t('Credit card registered:')} {props.payment.Code} {props.payment.NumberMasked}</Typography>
    <Button 
      onClick={() => props.onReset({ Number: '', NumberMasked: '', ExpirationDate: '', Code: ''})}
      type="submit"
      variant="contained"
    >{t('Pay.OtherCreditcardButton')}</Button>
  </div>
}

export default function ShowBooking(props:BookingProps):JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [appState] = useContext(AppContext);
  const { booking, amountPaid } = props;
  const [from, setFrom] = useState(booking.payment.AmountPaidByClient || 0);
  const toPay = booking.payment.TotalSalesPrice - (booking.payment.AmountPaidByClient || 0);
  const formatter = new Intl.NumberFormat(appState.Language, {
    style: 'currency',
    currency: booking.SalesCurrency,
    minimumFractionDigits: 2
  })
  
  useEffect(() => {
    setFrom(() => (booking.payment.AmountPaidByClient || 0) - amountPaid);
  }, [booking.payment.AmountPaidByClient]);

  const formatCurrency = (v:string|number|boolean) => formatter.format(parseFloat(v.toString()))

  return <div className={classes.booking}>
    <Typography variant="h5" component="h3">{t('BookingNumberLabel')} {booking.BookingNumber}</Typography>
    <Typography variant="h4" className={classes.balance}>{t('Pay.StillToPayLabel')} {<CountUp formatter={formatCurrency} to={toPay} from={booking.payment.AmountPaidByClient || 0} />}</Typography>
    <Typography variant="h4" className={classes.balance}>{t('Pay.AlreadyPaidLabel')} {(<CountUp formatter={formatCurrency} from={from} to={booking.payment.AmountPaidByClient || 0} />)}</Typography>
    <Typography variant="body1" className={classes.balance}>{t("Pay.TotalLabel")} {formatCurrency(booking.payment.TotalSalesPrice)}</Typography>
    <ServiceList services={booking.services} />
    {props.payment.Number == '' ? <PMTFrame onData={props.onPayment} onError={props.onPaymentError} /> : <ResetPaymentButton payment={props.payment} onReset={props.onPayment} />}
    </div>
} 